*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.bottom-5 {
  bottom: 1.25rem;
}

.left-\[max\(-7rem\,calc\(50\%-52rem\)\)\] {
  left: max(-7rem, 50% - 52rem);
}

.left-\[max\(45rem\,calc\(50\%\+8rem\)\)\] {
  left: max(45rem, 50% + 8rem);
}

.right-1\/2 {
  right: 50%;
}

.right-5 {
  right: 1.25rem;
}

.top-1\/2 {
  top: 50%;
}

.isolate {
  isolation: isolate;
}

.-z-10 {
  z-index: -10;
}

.-m-3 {
  margin: -.75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[577\/310\] {
  aspect-ratio: 577 / 310;
}

.size-10 {
  width: 2.5rem;
  height: 2.5rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.h-12 {
  height: 3rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[200\%\] {
  width: 200%;
}

.w-\[36\.0625rem\] {
  width: 36.0625rem;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-\[-30deg\] {
  --tw-skew-x: -30deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.gap-x-6 {
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}

.bg-\[radial-gradient\(45rem_50rem_at_top\,theme\(colors\.indigo\.100\)\,white\)\] {
  background-image: radial-gradient(45rem 50rem at top, #e0e7ff, #fff);
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#ff80b5\] {
  --tw-gradient-from: #ff80b5 var(--tw-gradient-from-position);
  --tw-gradient-to: #ff80b500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue-500 {
  --tw-gradient-from: #3b82f6 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b82f600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#9089fc\] {
  --tw-gradient-to: #9089fc var(--tw-gradient-to-position);
}

.to-purple-600 {
  --tw-gradient-to: #9333ea var(--tw-gradient-to-position);
}

.fill-gray-900 {
  fill: #111827;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: .75rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-sm\/6 {
  font-size: .875rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xl\/8 {
  font-size: 1.25rem;
  line-height: 2rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity, 1));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity, 1));
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-indigo-600\/10 {
  --tw-shadow-color: #4f46e51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity, 1));
}

.blur-2xl {
  --tw-blur: blur(40px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

html, body {
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

:root {
  --color-primary: #2584ff;
  --color-secondary: #00d9ff;
  --color-accent: #ff3400;
  --color-headings: #1b0760;
  --color-body: #918ca4;
  --color-body-darker: #5c5577;
  --color-border: #ccc;
  --border-radius: 30px;
}

*, :after, :before {
  box-sizing: border-box;
}

::selection {
  background: var(--color-primary);
  color: #fff;
}

html {
  font-size: 62.5%;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

body {
  color: var(--color-body);
  background-color: #fff;
  width: 100%;
  margin: 0;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  overflow-x: hidden;
}

h1, h2, h3 {
  color: var(--color-headings);
  line-height: 1.1;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.3;
}

p {
  margin-top: 0;
}

@media screen and (width >= 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

a {
  text-decoration: none;
}

.link-arrow {
  color: var(--color-accent);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}

.link-arrow:after {
  content: "-->";
  margin-left: 5px;
  transition: margin .15s;
}

.link-arrow:hover:after {
  margin-left: 10px;
}

@media screen and (width >= 1024px) {
  .link-arrow {
    font-size: 1.5rem;
  }
}

.badge {
  white-space: nowrap;
  border-radius: 20px;
  padding: .5rem 2rem;
  font-size: 2rem;
  font-weight: 600;
}

.badge--primary {
  background: var(--color-primary);
  color: #fff;
}

.badge--secondary {
  background: var(--color-secondary);
  color: #fff;
}

.badge--small {
  padding: .5rem 1.5rem;
  font-size: 1.6rem;
}

@media screen and (width >= 1024px) {
  .badge {
    font-size: 1.5rem;
  }

  .badge--small {
    font-size: 1.2rem;
  }
}

.list {
  padding-left: 0;
  list-style: none;
}

.list--inline .list__item {
  margin-right: 2rem;
  display: inline-block;
}

.list--tick {
  color: var(--color-headings);
  padding-left: 3rem;
  list-style-image: url("tick.5f604612.svg");
}

.list--tick .list__item {
  margin-bottom: 1rem;
  padding-left: .5rem;
}

@media screen and (width >= 1024px) {
  .list--tick .list__item {
    padding-left: 0;
  }
}

.icon {
  width: 40px;
  height: 40px;
}

.icon--small {
  width: 30px;
  height: 30px;
}

.icon--primary {
  fill: var(--color-primary);
}

.icon--white {
  fill: #fff;
}

.icon-container {
  background: #f3f9fa;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  display: inline-flex;
}

.icon-container--accent {
  background: var(--color-accent);
}

.btn {
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  border: 0;
  border-radius: 40px;
  outline: 0;
  margin: 1rem 0;
  padding: 2rem 4vw;
  font-size: 1.8rem;
  font-weight: 600;
}

.btn .icon {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
}

.btn--primary:hover {
  background: #3a8ffd;
}

.btn--secondary {
  background: var(--color-secondary);
  color: #fff;
}

.btn--secondary:hover {
  background: #05cdf0;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: #ec3000;
}

.btn--outline {
  color: var(--color-headings);
  border: 2px solid var(--color-headings);
  background: #fff;
}

.btn--outline:hover {
  background: var(--color-headings);
  color: #fff;
}

.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

.btn--block {
  width: 100%;
  display: inline-block;
}

.btn--small {
  padding: 1rem;
}

@media screen and (width >= 1024px) {
  .btn {
    font-size: 1.5rem;
  }
}

.input {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border);
  color: var(--color-headings);
  outline: 0;
  padding: 1.5rem 3.5rem;
  font-size: 2rem;
}

::placeholder {
  color: #cdcbd7;
}

.input-group {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  display: flex;
}

.input-group .input {
  border: 0;
  flex-grow: 1;
  width: 0;
  padding: 1.5rem 2rem;
}

.input-group .btn {
  margin: 4px;
}

@media screen and (width >= 1024px) {
  .input {
    font-size: 1.5rem;
  }
}

.card {
  border-radius: 7px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.card__header, .card__body {
  padding: 2rem 7%;
}

.card--primary .card__header {
  background: var(--color-primary);
  color: #fff;
}

.card--secondary .card__header {
  background: var(--color-secondary);
  color: #fff;
}

.card--primary .badge--primary {
  background: #126de4;
}

.card--secondary .badge--secondary {
  background: #02cdf1;
}

.plan {
  transition: transform .2s ease-out;
}

.plan__name {
  color: #fff;
  margin: 0;
  font-size: 2.4rem;
  font-weight: 500;
}

.plan__price {
  font-size: 5rem;
}

.plan__billing-cycle {
  opacity: .8;
  margin-right: 1rem;
  font-size: 2rem;
  font-weight: 300;
}

.plan__description {
  letter-spacing: 1px;
  font-size: 2rem;
  font-weight: 300;
  display: block;
}

.plan .list__item {
  margin-bottom: 2rem;
}

.plan--popular .card__header {
  position: relative;
}

.plan--popular .card__header:before {
  content: url("popular.38c0804f.svg");
  width: 40px;
  display: inline-block;
  position: absolute;
  top: -6px;
  right: 5%;
}

.plan:hover {
  transform: scale(1.05);
}

.plan--popular:hover {
  transform: scale(1.15);
}

@media screen and (width >= 1024px) {
  .plan--popular {
    transform: scale(1.1);
  }

  .plan__name {
    font-size: 1.4rem;
  }

  .plan__price {
    font-size: 5rem;
  }

  .plan__billing-cycle {
    font-size: 1.6rem;
  }

  .plan__description {
    font-size: 1.7rem;
  }
}

.media {
  margin-bottom: 4rem;
  display: flex;
}

.media__title {
  margin-top: 0;
}

.media__body {
  margin: 0 2rem;
}

.media__image {
  margin-top: 1rem;
}

.quote {
  color: var(--color-body-darker);
  font-size: 3rem;
  font-style: italic;
  line-height: 1.3;
}

.quote__text:before {
  content: open-quote;
}

.quote__text:after {
  content: close-quote;
}

.quote__author {
  margin-bottom: 0;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
}

.quote__organization {
  color: var(--color-headings);
  opacity: .4;
  font-size: 1.5rem;
  font-style: normal;
}

.quote__line {
  position: relative;
  bottom: 10px;
}

@media screen and (width >= 1024px) {
  .quote {
    font-size: 2rem;
  }

  .quote__author {
    font-size: 2.4rem;
  }

  .quote__organization {
    font-size: 1.6rem;
  }
}

.grid {
  gap: 2rem;
  width: 100%;
  max-width: 100%;
  display: grid;
  overflow-x: hidden;
}

@media screen and (width >= 768px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width >= 1024px) {
  .grid--1x3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.testimonial {
  padding: 3rem;
}

.testimonial .quote {
  margin: 2rem 0;
  font-size: 110%;
}

.testimonial__image {
  margin: 0 3rem;
  position: relative;
}

.testimonial__image > .icon-container {
  position: absolute;
  top: 3rem;
  right: -32px;
}

@media screen and (width >= 768px) {
  .testimonial__image {
    margin: 0;
  }

  .testimonial .quote {
    margin: 5rem 0 0 6rem;
    font-size: 120%;
    line-height: 1.5;
  }
}

.callout {
  border-radius: 5px;
  padding: 4rem;
}

.callout--primary {
  background: var(--color-primary);
  color: #fff;
}

.callout__heading {
  color: #fff;
  margin-top: 0;
  font-size: 3rem;
}

.callout .btn {
  place-self: center;
}

.callout__content {
  text-align: center;
}

@media screen and (width >= 768px) {
  .callout .grid--1x2 {
    grid-template-columns: 1fr auto;
  }

  .callout__content {
    text-align: left;
  }

  .callout .btn {
    justify-self: start;
    margin: 0 2rem;
  }
}

.collapsible__header {
  justify-content: space-between;
  display: flex;
}

.collapsible__heading {
  margin-top: 0;
  font-size: 3rem;
}

.collapsible__chevron {
  transition: transform .3s;
  transform: rotate(-90deg);
}

.collapsible__content {
  opacity: 0;
  max-height: 0;
  transition: all .3s;
  overflow: hidden;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
  opacity: 1;
  max-height: 100vh;
}

.block {
  --padding-vertical: 3rem;
  padding: var(--padding-vertical) 0;
  width: 100%;
}

.block__header {
  text-align: center;
  margin-bottom: 4rem;
}

.block__heading {
  margin-top: 0;
  font-size: 3rem;
}

.block--dark {
  color: #7b858b;
  background: #000;
}

.block--dark h1, .block--dark h2, .block--dark h3 {
  color: #fff;
}

.block--skewed-right {
  padding-bottom: calc(var(--padding-vertical)  + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
}

.block--skewed-left {
  padding-bottom: calc(var(--padding-vertical)  + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%);
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow-x: hidden;
}

@media screen and (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media screen and (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media screen and (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media screen and (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

.nav {
  background: #000;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.nav__list {
  width: 100%;
  margin: 0;
}

.nav__item {
  border-bottom: 1px solid #222;
  padding: .5rem 2rem;
}

.nav__item:last-of-type {
  border-bottom: none;
}

.nav__item > a {
  color: #d2d0db;
  transition: color .3s;
}

.nav__item > a:hover {
  color: #fff;
}

.nav__toggler {
  opacity: .5;
  cursor: pointer;
  transition: box-shadow .15s;
}

.nav.collapsible--expanded .nav__toggler {
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0 0 0 3px #666;
}

.nav__brand {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  transform: translateY(5px);
}

@media screen and (width >= 768px) {
  .nav__toggler {
    display: none;
  }

  .nav__list {
    opacity: 1;
    width: auto;
    max-height: 100%;
    font-size: 1.6rem;
    display: flex;
  }

  .nav__item {
    border: 0;
  }
}

.hero {
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  width: 100%;
  padding: 4rem 0;
}

.hero__header {
  align-items: center;
  gap: 4rem;
  margin-bottom: 3rem;
  display: flex;
}

.hero__image-container {
  border: 4px solid var(--color-accent);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 20px #ff34004d;
}

.hero__image {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .3s;
}

.hero__image:hover {
  transform: scale(1.1);
}

.hero__text {
  flex: 1;
}

.hero__tagline {
  color: var(--color-accent);
  letter-spacing: 1px;
  margin: .5rem 0;
  font-size: 2.4rem;
  font-weight: 500;
}

.hero__description {
  color: #b9c3cf;
  margin: 1rem 0 2rem;
  font-size: 1.8rem;
}

.hero__cta {
  text-align: center;
  align-items: center;
  gap: 2rem;
  margin-top: 4rem;
  display: flex;
}

.hero__social {
  gap: 1.5rem;
  display: flex;
}

.hero__social-link {
  color: #fff;
  font-size: 2rem;
  transition: color .3s;
}

.hero__social-link:hover {
  color: var(--color-accent);
}

@media screen and (width <= 640px) {
  .hero__header {
    padding: 0 1rem;
  }

  .hero__image-container {
    width: 120px;
    height: 120px;
  }
}

.block-domain .input-group {
  border: 0;
  max-width: 670px;
  margin: 4rem auto;
  box-shadow: 0 0 30px 20px #e6ebee;
}

.block-domain__prices {
  color: var(--color-headings);
  grid-template-rows: repeat(2, 6rem);
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  max-width: 700px;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 600;
  display: grid;
}

@media screen and (width >= 768px) {
  .block-domain__prices {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }
}

.block-plans .grid {
  gap: 8rem 4rem;
}

.block-plans .card {
  max-width: 500px;
  margin: 0 auto;
}

.feature {
  gap: 4rem 2rem;
  width: 100%;
  max-width: 100%;
  margin: 12rem 0;
  overflow-x: hidden;
}

.feature:first-of-type {
  margin-top: 2rem;
}

.feature__heading {
  margin: 1rem 0;
}

@media screen and (width >= 768px) {
  .feature:nth-of-type(2n) .feature__content {
    order: 2;
  }

  .block-showcase .grid {
    grid-template-columns: 50% 50%;
  }

  .block-showcase__image {
    justify-self: end;
  }

  .block-showcase__image > img {
    width: auto;
    max-width: 700px;
  }
}

.footer {
  background: #232323;
  width: 100%;
  padding-top: 10rem;
}

.footer a {
  color: #777;
  transition: color .3s;
}

.footer a:hover {
  color: #fff;
}

.footer__section {
  border-bottom: 1px solid #393939;
  padding: 2rem;
}

.footer__section .list {
  margin: 0;
}

.footer__heading {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.5;
}

.footer__brand {
  text-align: center;
  margin-top: 5rem;
}

.footer__brand img {
  max-width: 230px;
}

.footer__copyright {
  color: #fff;
  opacity: .3;
  font-size: 2.1rem;
}

@media screen and (width >= 768px) {
  .footer {
    font-size: 1.5rem;
  }

  .footer__sections {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }

  .footer .collapsible__chevron {
    display: none;
  }

  .footer .collapsible__content {
    opacity: 1;
    max-height: 100%;
  }

  .footer__brand {
    order: -1;
    margin-top: 1rem;
  }

  .footer__copyright {
    font-size: 1.5rem;
  }

  .footer__section {
    border: 0;
  }

  .footer__heading {
    font-size: 1.6rem;
  }
}

.callout-signup {
  transform: translateY(5rem);
}

.education {
  margin-top: 2rem;
}

.education__item {
  margin-bottom: 2rem;
}

.education__item h3 {
  color: var(--color-headings);
  margin-bottom: .5rem;
}

.education__program {
  margin-bottom: .3rem;
  font-weight: 600;
}

.education__details {
  color: var(--color-body);
  margin-bottom: .3rem;
}

.education__period {
  color: #666;
  font-size: 1rem;
}

.experience__item {
  background: #fff;
  border-radius: 7px;
  margin-bottom: 3rem;
  padding: 3rem;
  transition: transform .2s ease-out;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.experience__item:hover {
  transform: translateY(-5px);
}

.experience__item h3 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  font-size: 2.4rem;
}

.experience__tech {
  color: var(--color-primary);
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.experience__description {
  color: var(--color-body);
  margin-bottom: 2rem;
}

.experience__item .btn {
  padding: 1rem 2rem;
  font-size: 1.6rem;
}

@media screen and (width >= 768px) {
  .experience__item {
    padding: 4rem;
  }
}

.achievements {
  margin-top: 4rem;
}

.achievement__item {
  background: #fff;
  border-radius: 7px;
  gap: 2rem;
  margin-bottom: 3rem;
  padding: 3rem;
  transition: transform .2s ease-out;
  display: grid;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.achievement__item:hover {
  transform: translateY(-5px);
}

.achievement__item h3 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  font-size: 2.4rem;
}

.achievement__description {
  color: var(--color-body);
  margin-bottom: 2rem;
}

.achievement__image img {
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 5px;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

@media screen and (width <= 768px) {
  .achievement__image img {
    max-width: 100%;
  }
}

.visitor-counter {
  text-align: center;
  background: var(--color-primary);
  color: #fff;
  border-radius: 7px;
  margin: 2rem 0;
  padding: 2rem;
}

.visitor-counter h2 {
  margin-bottom: 1rem;
}

.feedback-form {
  background: #fff;
  border-radius: 7px;
  max-width: 600px;
  margin: 0 auto;
  padding: 3rem;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.feedback-form h2 {
  color: var(--color-headings);
  text-align: center;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 2rem;
}

.form-group label {
  color: var(--color-headings);
  margin-bottom: 1rem;
  font-weight: 500;
  display: block;
}

.form-control {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  font-size: 1.6rem;
  transition: border-color .3s;
}

.form-control:focus {
  border-color: var(--color-primary);
  outline: none;
}

textarea.form-control {
  resize: vertical;
  min-height: 100px;
}

.visitor-counter {
  text-align: center;
  background: var(--color-primary);
  color: #fff;
  border-radius: 5px;
  margin-top: 3rem;
  padding: 2rem;
}

.visitor-counter h3 {
  color: #fff;
  margin-bottom: 1rem;
}

#visitorCount {
  font-size: 2.4rem;
  font-weight: bold;
}

.feedback-message {
  color: #fff;
  text-align: center;
  background: #4caf50;
  border-radius: 5px;
  margin-top: 2rem;
  padding: 1rem;
  animation: .3s ease-out slideIn;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav__social {
  gap: 2rem;
  display: none;
}

.nav__social-link {
  color: #fff;
  font-size: 2rem;
  transition: color .3s;
}

.nav__social-link:hover {
  color: var(--color-accent);
}

@media screen and (width >= 768px) {
  .nav__social {
    margin-left: 2rem;
    display: flex;
  }
}

.page-transition {
  animation: .5s ease-out fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero__cta, .text-center {
  text-align: center;
}

.grid--1x3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  display: grid;
}

.education__item.card {
  background: var(--color-white);
  border-radius: 7px;
  padding: 2rem;
  transition: transform .2s ease-in;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.education__item.card:hover {
  transform: translateY(-5px);
}

@media screen and (width <= 768px) {
  .grid--1x3 {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.text-3xl {
  margin-bottom: 0;
}

.experience {
  margin-top: 0;
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity, 1));
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-offset-\[-4px\]:focus-visible {
  outline-offset: -4px;
}

.focus-visible\:outline-gray-900:focus-visible {
  outline-color: #111827;
}

.dark\:block:is(.dark *) {
  display: block;
}

.dark\:hidden:is(.dark *) {
  display: none;
}

.dark\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.dark\:bg-blue-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}

.dark\:bg-dark-primary:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 26 / var(--tw-bg-opacity, 1));
}

.dark\:bg-dark-secondary:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(45 45 45 / var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.dark\:text-blue-100:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity, 1));
}

.dark\:text-dark-text:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.dark\:text-gray-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.dark\:text-gray-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.dark\:text-gray-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.dark\:text-gray-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-blue-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-pink-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-white:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .sm\:mr-28 {
    margin-right: 7rem;
  }

  .sm\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:text-2xl\/9 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .sm\:before\:flex-1:before {
    content: var(--tw-content);
    flex: 1;
  }
}

@media (width >= 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:text-left {
    text-align: left;
  }
}

@media (width >= 1024px) {
  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 1280px) {
  .xl\:mr-16 {
    margin-right: 4rem;
  }

  .xl\:origin-center {
    transform-origin: center;
  }
}
/*# sourceMappingURL=index.d13ca1ca.css.map */
